import React from 'react'
import Layout from '../components/Layout'
import * as styles  from '../styles/project-details.module.css'
import { graphql } from 'gatsby'
import { Row, Col}  from 'react-bootstrap'

import LeftNavBarWordpress from '../components/examples/wordpress/LeftNavBarWordpress'
import PreviousNext from '../components/Previous_Next'

import Seo from '../components/SEO'

export default function WordPressDetails({data, pageContext}) {

  const { html} = data.tutorial
  const { title, keywords, published, modified, description, type} = data.tutorial.frontmatter

  const { next, previous } = pageContext


    return (

      <Layout>
          <Seo
            title={title}
            description={description}
            keywords={keywords}
            published={published}
            modified={modified}
            type={type}
          />
            <Row>
                <Col xs={12} md={3} className="d-none d-lg-block">
                    <h4>Table of Content</h4>
                    <LeftNavBarWordpress></LeftNavBarWordpress>
                </Col>
                <Col xs={12} md={9}>
                <div className={styles.details}>
                    <h1>{title}</h1>
                    <small>Last modified: {modified}</small>
                    <PreviousNext baseURL={"/"} previous={previous} next={next}></PreviousNext>               

                    <div className={styles.html} dangerouslySetInnerHTML={{ __html: html }} />
                    <PreviousNext baseURL={"/"} previous={previous} next={next}></PreviousNext>               

                </div>
                </Col>
            </Row>

        </Layout>
    )
}

export const query = graphql`
  query wordpressDetailTutorial($slug: String) {
    tutorial: markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      id
      frontmatter {
        title
        keywords
        description
        published(formatString: "MMMM DD, YYYY")
        modified(formatString: "MMMM DD, YYYY")
        type
        featured
      }
    }
    site:site {
      siteMetadata {
        siteUrl: url
      }
    }
  }
`
